<template>
  <div id="mian">
    <div>
      <div class="content">
        <div class="desc">
          <h5 class="jsxx">交易信息</h5>
          <div>
            <el-button v-if="false" class="reEmailBt" type="primary">重发邮件</el-button>
          </div>
        </div>
        <ul class="listBox">
          <li>
            <span>商户订单号</span>
            <i class="hidden1">{{detail.mchOrderNo}}</i>
          </li>
          <li>
            <span>交易流水号</span>
            <i class="hidden1">{{detail.plfOrderNo}}</i>
          </li>
          <li>
            <span>三方订单号</span>
            <i class="hidden1">{{detail.upsOrderNo}}</i>
          </li>
          <li>
            <span>三方流水号</span>
            <i class="hidden1">{{detail.thdOrderNo}}</i>
          </li>
          <li>
            <span>分账订单号</span>
            <i class="hidden1">{{detail.mchAllocOrderNo}}</i>
          </li>
          <li>
            <span>分账类型</span>
            <i v-if="detail.allocFundType == '11'">实时分账</i>
            <i v-if="detail.allocFundType == '12'">延迟分账</i>
            <i v-if="detail.allocFundType == '13'">多次分账</i>
          </li>
          <li>
            <span>商户名称</span>
            <i class="hidden1">
              <el-tooltip class="item" effect="light" :content="detail.mchName" placement="top">
                <b>{{detail.mchName}}</b>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>商户编号</span>
            <i class="hidden1">{{detail.mchId}}</i>
          </li>
          <li>
            <span>店铺名称</span>
            <i class="hidden1">
              <el-tooltip class="item" effect="light" :content="detail.storeName" placement="top">
                <b>{{detail.storeName}}</b>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>店铺编号</span>
            <i class="hidden1">{{detail.storeId}}</i>
          </li>
          <li>
            <span>报备商户</span>
            <i class="hidden1">{{detail.tradeMchName}}</i>
          </li>
          <li>
            <span>报备商户号</span>
            <i class="hidden1">{{detail.tradeMchNo}}</i>
          </li>
          <li>
            <span>代理商名称</span>
            <i class="hidden1">{{detail.agentName}}</i>
          </li>
          <li>
            <span>服务商名称</span>
            <i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.servicerName"
                placement="top"
              >
                <b>{{detail.servicerName}}</b>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>所属机构</span>
            <i class="hidden1">{{detail.orgName}}</i>
          </li>
          <li>
            <span>渠道商户号</span>
            <i class="hidden1">{{detail.channelMchNo}}</i>
          </li>
          <li>
            <span>创建时间</span>
            <i class="hidden1">{{detail.createTime}}</i>
          </li>
          <li>
            <span>交易时间</span>
            <i class="hidden1">{{detail.tradeTime}}</i>
          </li>
          <li>
            <span>产品类型</span>
            <i class="hidden1">{{detail.productName}}</i>
          </li>
          <li>
            <span>交易状态</span>
            <i v-if="detail.orderStatus == 'NOTPAY'">未支付</i>
            <i v-if="detail.orderStatus == 'SUCCESS'">交易成功</i>
            <i v-if="detail.orderStatus == 'REFUND'">转入退款</i>
            <i v-if="detail.orderStatus == 'CLOSED'">订单关闭</i>
            <i v-if="detail.orderStatus == 'PAYERROR'">支付失败</i>
            <i v-if="detail.orderStatus == 'USERPAYING'">用户支付中</i>
            <i v-if="detail.orderStatus == 'REVOKED'">已撤销</i>
          </li>
          <li>
            <span>交易金额</span>
            <i class="hidden1">{{detail.orderAmount ? detail.orderAmount/100: '' | formatMoney}}</i>
          </li>
          <li>
            <span>手续费</span>
            <i class="hidden1">{{detail.fee ? detail.fee/100:'' | formatMoney}}</i>
          </li>
          <li>
            <span>商品名称</span>
            <i class="hidden1">
              <el-tooltip class="item" effect="light" :content="detail.body" placement="top">
                <b>{{detail.body}}</b>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>商品详情</span>
            <i class="hidden1">
              <el-tooltip class="item" effect="light" :content="detail.detail" placement="top">
                <b>{{detail.detail}}</b>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>微信OpenID</span>
            <i class="hidden1">{{detail.openId}}</i>
          </li>
          <li>
            <span>微信APPID</span>
            <i class="hidden1">{{detail.appId}}</i>
          </li>
          <li>
            <span>付款银行</span>
            <i class="hidden1">{{bank[detail.bankCode]?bank[detail.bankCode]:detail.bankCode}}</i>
          </li>
          <li>
            <span>附加数据</span>
            <i class="hidden1">
              <el-tooltip class="item" effect="light" :content="detail.attach" placement="top">
                <b>{{detail.attach}}</b>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>终端IP</span>
            <i class="hidden1">{{detail.terminalIp}}</i>
          </li>
          <li>
            <span>通知地址</span>
            <i class="hidden1">
              <el-tooltip class="item" effect="light" :content="detail.notifyUrl" placement="top">
                <b>{{detail.notifyUrl}}</b>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>页面回调地址</span>
            <i class="hidden1">
              <el-tooltip class="item" effect="light" :content="detail.returnUrl" placement="top">
                <b>{{detail.returnUrl}}</b>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>分账通知地址</span>
            <i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.allocFundNotifyUrl"
                placement="top"
              >
                <b>{{detail.allocFundNotifyUrl}}</b>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>响应码</span>
            <i class="hidden1">{{detail.resultCode}}</i>
          </li>
          <li>
            <span>响应码描述</span>
            <i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.resultMessage"
                placement="top"
              >
                <b>{{detail.resultMessage}}</b>
              </el-tooltip>
            </i>
          </li>
        </ul>
      </div>
      <div class="desc">
        <h5 class="jsxx">分账信息</h5>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td>
              <p>分账商户名称</p>
            </td>
            <td>
              <p>分账商户编号</p>
            </td>
            <td>
              <p>分账流水号</p>
            </td>
            <td>
              <p>分账状态</p>
            </td>
            <td>
              <p>分账金额</p>
            </td>
          </tr>
          <tr v-for="(item,index) in allocOrders" :key="index">
            <td>
              <p>{{item.allocMchName}}</p>
            </td>
            <td>
              <p>{{item.allocMchId}}</p>
            </td>
            <td>
              <p>{{item.allocTrxNo}}</p>
            </td>
            <td>
              <p v-if="item.allocStatus == 'PROCESSING'">处理中</p>
              <p v-if="item.allocStatus == 'FAIL'">分账失败</p>
              <p v-if="item.allocStatus == 'SUCCESS'">分账成功</p>
            </td>
            <td>
              <p>{{item.allocAmount/100}}</p>
            </td>
          </tr>
        </table>
      </div>
      <div class="desc">
        <h5 class="jsxx">分账通知信息</h5>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td>
              <p>通知时间</p>
            </td>
            <td>
              <p>请求类型</p>
            </td>
            <td>
              <p>通知状态</p>
            </td>
            <td>
              <p>通知结果</p>
            </td>
            <td>
              <p>状态码</p>
            </td>
            <td>
              <p>返回信息</p>
            </td>
          </tr>
          <tr v-for="(item,index) in notices" :key="index">
            <td>
              <p>{{item.notifyTime}}</p>
            </td>
            <td>
              <p v-if="item.notifyType == 'PLATFORM'">平台</p>
              <p v-if="item.notifyType == 'THIRD'">第三方</p>
            </td>
            <td>
              <p v-if="item.reachStatus == true">已到达</p>
              <p v-if="item.reachStatus == false || item.reachStatus == null">未响应</p>
            </td>
            <td>
              <p v-if="item.notifyStatus == 'SUCCESS'">成功</p>
              <p v-if="item.notifyStatus == 'FAIL'">失败</p>
            </td>
            <td>
              <p>{{item.resultCode}}</p>
            </td>
            <td>
              <p>{{item.resultMessage}}</p>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { allocfundDetail } from "@/api/operation/inform.js";
import { getBank } from "@/utils/bank.js";
export default {
  data() {
    return {
      crumbs: "交易详情",
      notices: [],
      detail: {},
      allocOrders: [],
      bank: getBank(),
    };
  },
  created() {
    this.getLists();
  },
  activated() {
    this.getLists();
  },
  methods: {
    //菜单切换
    navClick(data) {
      this.crumbs = data;
    },
    // 获取列表
    getLists() {
      allocfundDetail(this.$route.query.allocOrderId)
        .then((res) => {
          let resOther = res.resultData.detail;
          for (let item in resOther) {
            if (resOther[item] == null || resOther[item] == "") {
              resOther[item] = "";
            }
          }
          this.detail = resOther;
          this.notices = res.resultData.notices;
          this.allocOrders = res.resultData.allocOrders;
        })
        .catch();
    },
  },
};
</script>
<style scoped>
@import "../../../../assets/css/desc.css";
@import "../../../../assets/css/detail.css";
.tab1{
  min-height: auto !important;
}
</style>
